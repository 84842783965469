// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/ui/dialog.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/ui/dialog.tsx");
  import.meta.hot.lastModified = "1728334464000";
}
// REMIX HMR END

import * as React from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { X } from "lucide-react";
import { cn } from "~/lib/utils";
const Dialog = DialogPrimitive.Root;
const DialogTrigger = DialogPrimitive.Trigger;
const DialogPortal = DialogPrimitive.Portal;
const DialogClose = DialogPrimitive.Close;
const DialogOverlay = React.forwardRef(_c = ({
  className,
  ...props
}, ref) => <DialogPrimitive.Overlay ref={ref} className={cn("fixed inset-0 z-50 bg-white/80 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 dark:bg-stone-950/80", className)} {...props} />);
_c2 = DialogOverlay;
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;
const DialogContent = React.forwardRef(_c3 = ({
  className,
  ...props
}, ref) => <DialogPortal>
    <DialogOverlay />
    <DialogPrimitive.Content ref={ref} className={cn("fixed left-[50%] top-[50%] z-50 grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 border border-stone-200 bg-white p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg md:w-full dark:border-stone-800 dark:bg-stone-950", className)} {...props} />
  </DialogPortal>);
_c4 = DialogContent;
DialogContent.displayName = DialogPrimitive.Content.displayName;
const DialogHeader = ({
  className,
  ...props
}) => <div className={cn("flex flex-col space-y-1.5 text-center sm:text-left", className)} {...props} />;
_c5 = DialogHeader;
DialogHeader.displayName = "DialogHeader";
const DialogFooter = ({
  className,
  ...props
}) => <div className={cn("flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2", className)} {...props} />;
_c6 = DialogFooter;
DialogFooter.displayName = "DialogFooter";
const DialogTitle = React.forwardRef(_c7 = ({
  className,
  ...props
}, ref) => <DialogPrimitive.Title ref={ref} className={cn("text-lg font-semibold leading-none tracking-tight", className)} {...props} />);
_c8 = DialogTitle;
DialogTitle.displayName = DialogPrimitive.Title.displayName;
const DialogDescription = React.forwardRef(_c9 = ({
  className,
  ...props
}, ref) => <DialogPrimitive.Description ref={ref} className={cn("text-sm text-stone-500 dark:text-stone-400", className)} {...props} />);
_c10 = DialogDescription;
DialogDescription.displayName = DialogPrimitive.Description.displayName;
const DialogCloses = React.forwardRef(_c11 = ({
  className,
  children,
  ...props
}, ref) => <DialogPrimitive.Close ref={ref} className={cn("", className)} {...props}>
    <X className="h-4 w-4" />
    <span className="sr-only">Close</span>
  </DialogPrimitive.Close>);
_c12 = DialogCloses;
DialogCloses.displayName = DialogPrimitive.Close.displayName;
export { Dialog, DialogPortal, DialogOverlay, DialogClose, DialogCloses, DialogTrigger, DialogContent, DialogHeader, DialogFooter, DialogTitle, DialogDescription };
var _c, _c2, _c3, _c4, _c5, _c6, _c7, _c8, _c9, _c10, _c11, _c12;
$RefreshReg$(_c, "DialogOverlay$React.forwardRef");
$RefreshReg$(_c2, "DialogOverlay");
$RefreshReg$(_c3, "DialogContent$React.forwardRef");
$RefreshReg$(_c4, "DialogContent");
$RefreshReg$(_c5, "DialogHeader");
$RefreshReg$(_c6, "DialogFooter");
$RefreshReg$(_c7, "DialogTitle$React.forwardRef");
$RefreshReg$(_c8, "DialogTitle");
$RefreshReg$(_c9, "DialogDescription$React.forwardRef");
$RefreshReg$(_c10, "DialogDescription");
$RefreshReg$(_c11, "DialogCloses$React.forwardRef");
$RefreshReg$(_c12, "DialogCloses");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;